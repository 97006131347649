.roomPreviewLoader {
    height: 232.23px;
    background-repeat: no-repeat;
    background-image:
        linear-gradient(90deg, transparent, rgba(255, 255, 255, .7), transparent),
        linear-gradient(#e2e2e2 178.23px, transparent 0),
        linear-gradient(#e2e2e2 36.812px, transparent 0),
        linear-gradient(#e2e2e2 24.6px, transparent 0),
        linear-gradient(#e2e2e2 29.6px, transparent 0),
        linear-gradient(#e2e2e2 113.600px, transparent 0);
    
    background-size:
        50px 232.23px,	/* highlight */
        268px 178.23px,
        227.33px 36.812px,
        200.53px 24.6px,
        347.33px 29.6px,
        166.038px 113.600px;

    background-position:
        0 0,	/* highlight */
        15px 26.800px,
        298px 33.950px,
        298px 90.762px,
        298px 135.125px,
        693.156px 33.950px;
    animation: roomPreviewLoaderShine 1s infinite;
}

@keyframes roomPreviewLoaderShine {
to {
    background-position:
        100% 0,	/* move highlight to right */
        15px 26.800px,
        298px 33.950px,
        298px 90.762px,
        298px 135.125px,
        693.156px 33.950px;
    }
}
