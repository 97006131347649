.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  width: 120px;
  height: 30px;
  border-radius: 2px;
  background-color: #444;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2;
  letter-spacing: normal;
  color: #fff;
  visibility: hidden;
  text-align: center;
  position: absolute;
  z-index: 1;
  /* top: -60%; */
  top: -40px;
  left: 50%;
  margin-left: -60px;
  padding: 0 15px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: -35%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-color: #444 transparent;
  border-style: solid;
  border-width: 20px 20px 0px 20px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
