.roomPriceSection {
    height: 98px;
    background-repeat: no-repeat;
    background-image:
        linear-gradient(90deg, transparent, rgba(255, 255, 255, .7), transparent),
        linear-gradient(#e2e2e2 26.600px, transparent 0),
        linear-gradient(#e2e2e2 20px, transparent 0),
        linear-gradient(#e2e2e2 26px, transparent 0),
        linear-gradient(#e2e2e2 26.600px, transparent 0),
        linear-gradient(#e2e2e2 20px, transparent 0),
        linear-gradient(#e2e2e2 26px, transparent 0),
        linear-gradient(#e2e2e2 26.600px, transparent 0),
        linear-gradient(#e2e2e2 20px, transparent 0),
        linear-gradient(#e2e2e2 26px, transparent 0),
        linear-gradient(#e2e2e2 26.600px, transparent 0),
        linear-gradient(#e2e2e2 20px, transparent 0),
        linear-gradient(#e2e2e2 26px, transparent 0),
        linear-gradient(#e2e2e2 26.600px, transparent 0),
        linear-gradient(#e2e2e2 20px, transparent 0),
        linear-gradient(#e2e2e2 26px, transparent 0),
        linear-gradient(#e2e2e2 26.600px, transparent 0),
        linear-gradient(#e2e2e2 20px, transparent 0),
        linear-gradient(#e2e2e2 26px, transparent 0),
        linear-gradient(#e2e2e2 26.600px, transparent 0),
        linear-gradient(#e2e2e2 20px, transparent 0),
        linear-gradient(#e2e2e2 26px, transparent 0);

    background-size:
        50px 98px,
        182px 26.600px,
        50px 20px,
        100px 26px,
        182px 26.600px,
        50px 20px,
        100px 26px,
        182px 26.600px,
        50px 20px,
        100px 26px,
        182px 26.600px,
        50px 20px,
        100px 26px,
        182px 26.600px,
        50px 20px,
        100px 26px,
        182px 26.600px,
        50px 20px,
        100px 26px,
        182px 26.600px,
        50px 20px,
        100px 26px;

    background-position:
        0 0,
        0px 0px,
        65px 35.600px,
        40px 65px,
        190px 0px,
        250px 35.600px,
        225px 65px,
        380px 0px,
        450px 35.600px,
        425px 65px,
        570px 0px,
        635px 35.600px,
        610px 65px,
        760px 0px,
        820px 35.600px,
        795px 65px,
        950px 0px,
        1015px 35.600px,
        990px 65px,
        1140px 0px,
        1190px 35.600px,
        1165px 65px;

        animation: roomPriceSectionShine 1s infinite;
}

@keyframes roomPriceSectionShine {
to {
    background-position:
        100% 0,	/* move highlight to right */
        0px 0px,
        65px 35.600px,
        40px 65px,
        190px 0px,
        250px 35.600px,
        225px 65px,
        380px 0px,
        450px 35.600px,
        425px 65px,
        570px 0px,
        635px 35.600px,
        610px 65px,
        760px 0px,
        820px 35.600px,
        795px 65px,
        950px 0px,
        1015px 35.600px,
        990px 65px,
        1140px 0px,
        1190px 35.600px,
        1165px 65px;
    }
}