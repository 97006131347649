/* Basic CSS setup */
* {
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: #fff;
  color: #555555;
  font-family: 'Lato', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
}

.custom-style p {
  display: inline;
}
