:root {
    --theme_color: #f5ab35;
  }

.circle {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: var(--theme_color);
    position: relative;
}

.circle .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 50px;
}

	