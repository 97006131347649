  

  :root {
    --theme_color: #f5ab35;
  }



/* CSS React Dates Overrides */
.DateRangePicker {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: var(--theme_color);;
    border: 1px double var(--theme_color);;
}

.CalendarDay__selected_span {
    background: #f6d7a6;
    border: 1px double #f6d7a6;
    color: #eb2026;
}

.CalendarDay__hovered_span:active {
    background: #f6d7a6;
    border: 1px double #f6d7a6;
    color: #eb2026;
}

.CalendarDay__hovered_span, 
.CalendarDay__hovered_span:hover {
    background: #f6d7a6;
    border: 1px double #f6d7a6;
    color: #eb2026;
}

.CalendarDay__selected_span:active, 
.CalendarDay__selected_span:hover {
    background: var(--theme_color);;
    border: 1px double var(--theme_color);;
}

.DateRangePickerInput__withBorder {
    width: 100%;
    border: 1px solid transparent;
}

.DateInput {
    height: 2.2rem;
    line-height: 2.2rem;
    border: 1px solid var(--theme_color);;
    border-radius: 2px;
    width: 45%;
}

.DateInput input[type=text]:not(.browser-default) {
    height: 1rem;
    border-bottom: none;
    padding-left: 6%;
    font-size: 100%;
    width: 100%;
    cursor: pointer;
}

.DateInput {
    cursor: pointer;
    padding-right: 10px;
    background: url('../img/calendar.png') no-repeat scroll right center #ffffff;
    background-origin: content-box;
}

.DateInput_1:first-of-type::before {
    content: "Check In";
    color: #b0b0b0;
    position: absolute;
    top: -95%;
    font-weight: 400;
    font-size: 100%;
}

.DateInput_1:nth-child(3):before {
    content: "Check Out";
    color: #b0b0b0;
    position: absolute;
    top: -95%;
    font-weight: 400;
    font-size: 100%;
}

.DateInput_1:last-of-type::before {
    content: "Check Out";
    color: #b0b0b0;
    position: absolute;
    top: -95%;
    font-weight: 400;
    font-size: 100%;
}

/* .CalendarDay__default {
    color: #000000;
} */

.CalendarMonth_table  tr {
    border-bottom: none;
}

.CalendarMonth_table td, th {
    padding: 10px 5px;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid var(--theme_color);;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid var(--theme_color);;
}

.DayPicker__withBorder  {
    -webkit-box-shadow: 0px 0px 16px -1px rgba(72,72,72,0.89); 
box-shadow: 0px 0px 16px -1px rgba(72,72,72,0.89);
    /* -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75);
box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.75); */
}
/* CSS React Dates Overrides for Single Date Picker */

.hero-box-singledatepicker {
    width: 50%
}

.single-date-picker-wrap .SingleDatePicker {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.single-date-picker-wrap .SingleDatePickerInput {
    width: 100%;
}

.single-date-picker-wrap .SingleDatePickerInput__withBorder {
    border: none;
}

.single-date-picker-wrap .DateInput {
    width: 100%;
}

.single-date-picker-wrap .DateInput_1::before {
    content: "Check In";
}