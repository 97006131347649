/* CSS style for landing page */

:root {
    --theme_color: #f5ab35;
  }


/* CSS landing page header wrapper */
.landing-page-style {
	width: 100%;
	height: 15vh;
	background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.hotel-list-wrapper .card {
    transition: 0.3s ease-in-out all;
    transform: scale(1);
    box-shadow: 0 0 6px rgba(0,0,0,.2);
}

.hotel-list-wrapper .card:hover {
    box-shadow: 1px 1px 4px 0 rgba(0,0,10,0.04), 0 40px 100px -20px rgba(0,0,10,0.2), 0 30px 70px -30px rgba(0,0,0,0.4);
    margin-top: -3px;
    transform: scale(1.01);
}

/* CSS fixed the size of image cards */
.hotel-list-wrapper .card img {
    width: 397px;
    height: 288px;
}

/* CSS hotel listing heading */
.hotel-list-wrapper h2{
    color: #696969;
    text-transform: capitalize;
}

.hotel-list-wrapper h2:after {
    display: none;
    content: " ";
    width: 100px;
    margin: 0 auto;
    margin-top: 20px;
}

/* CSS hote listing paragraph */
.heading-para {
    text-align: center;
    margin-top: 2%;
}

/* CSS hotel booking button */
.btn-book {
    background-color: var(--theme_color) !important;
    text-decoration: none;
    color: #fff;
    text-align: center;
    letter-spacing: .5px;
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
    cursor: pointer;
}

/* CSS hotel booking button hover and active */
.btn-book:hover,
.btn-book:active {
	background-color: var(--theme_color) !important;
}

/* CSS footer wrap */
.footer-wrap {
    background-color: #696969;
    width: 100%;
    height: auto;
}

/* CSS overriding margin bottom of default row class */
.footer-wrap .row {
    margin-bottom: 0;
}


/* CSS footer box wrapper */
.footer-wrap .footer-box {
    width: 20%;
    margin: 0 auto;
    display: flex;
    align-items:center;
    text-align: center;
    padding: 0.5% 0;
 }

 /* CSS footer span */
 .footer-wrap .footer-box span {
    padding-bottom: 1%;
    padding-left: 6%;
    color: #fff;
    font-weight: 400;
 }

 /* CSS footer image booking jini */
 .footer-wrap .footer-box img {
     height: 60px;
 }