		.aboutHotelLoader {
			height: 424.788px;
			background-repeat: no-repeat;
			background-image:
				linear-gradient(90deg, transparent, rgba(255, 255, 255, .7), transparent),
				linear-gradient(#e2e2e2 21.6px, transparent 0),
				linear-gradient(#e2e2e2 241.450px, transparent 0),
				linear-gradient(#e2e2e2 79.200px, transparent 0),
				linear-gradient(#e2e2e2 26.400px, transparent 0),
				linear-gradient(#e2e2e2 26.400px, transparent 0),
				linear-gradient(#e2e2e2 26.400px, transparent 0),
				linear-gradient(#e2e2e2 26.400px, transparent 0);

			background-size:
				50px 424.788px,
				300px 21.6px,
				362.175px 241.450px,
				755.137px 79.200px,
				379.325px 26.400px,
				345.325px 26.400px,
				379.325px 26.400px,
				345.325px 26.400px;

			background-position:
				0 0,
				25px 25px,
				25px 69.845px,
				440.175px 69.845px,
				440.175px 200px,
				850px 200px,
				440.175px 240px,
				850px 240px;
			animation: aboutHotelLoadershine 1s infinite;
		}

		@keyframes aboutHotelLoadershine {
		to {
			background-position:
				100% 0,	/* move highlight to right */
            	25px 25px,
				25px 69.845px,
				440.175px 69.845px,
				440.175px 200px,
				850px 200px,
				440.175px 240px,
				850px 240px;
            }
        }
