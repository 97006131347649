/* Big tablet to 4000px (widths smaller than the 4000px) */

:root {
  --theme_color: #f5ab35;
}



@media screen and (max-width: 4000px) {
  .hero-text-box {
    width: 100%;
  }

  .room-box-left {
    float: left;
    width: 70%;
  }

  .room-box-right {
    float: right;
    width: 30%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    /* z-index: 2; */
  }

  .not-available li {
    text-align: right;
  }

  .DateRangePickerInput_arrow_svg {
    visibility: hidden !important;
  }

  .DateRangePickerInput_arrow_svg button:focus {
    outline: none;
    background-color: none !important;
  }

  .bm-burger-button {
    display: none !important;
  }

  .showMessage {
    width: 85%;
    height: 30px;
    top: 100px;
  }

  .showMessage p {
    margin-left: 27%;
  }

  .showMessage-modify {
    width: 85%;
    top: 65px;
  }

  .showMessage-modify p {
    margin-left: 27%;
  }


  .modify-message {
    width: 100%;
    top: 70px;
  }

  .modify-message p {
    margin-left: 15%;
  }


  .infants {
    width: 30% !important;
    padding-left: 0px !important;
  }

  .tab-screen {
    width: 140px !important;
    top: 14px !important;
  }

  .headerBackground {
    background-attachment: fixed;
  }

  .banner-section-hotel-name {
    display: none;
  }
}

/* Big tablet to 1300px (widths smaller than the 1300px) */
@media screen and (max-width: 1300px) {
  .hero-text-box {
    width: 100%;
  }

  header {
    background-size: cover !important;
  }

  .hero-box {
    top: 30%;
  }

  .room-box-left {
    float: left;
    width: 70%;
  }

  .room-box-right {
    float: right;
    width: 30%;
  }

  .room-price-summary {
    font-size: 70%;
  }

  .DateInput:after {
    left: 79% !important;
    top: -110% !important;
  }

  .showMessage {
    width: 85%;
  }

  .showMessage p {
    margin-left: 16%;
  }

  .showMessage-modify {
    width: 85%;
  }

  .showMessage-modify p {
    margin-left: 16%;
  }

  .modify-message {
    width: 100%;
  }

  .modify-message p {
    margin-left: 16%;
  }

  .tab-screen {
    width: 140px !important;
    top: 15px !important;
  }
}

/* Big tablet to large screen: from 1024px to 1200px */
@media only screen and (max-width: 1200px) {
  .room-box-left {
    float: left;
    width: 70%;
  }

  .room-box-right {
    float: right;
    width: 30%;
  }

  header {
    background-size: auto !important;
  }

  .hero-text-box {
    width: 100%;
    margin-top: 2%;
  }

  .hero-box {
    position: absolute;
    top: 20% !important;
  }

  .fare-calendar .calendar-day {
    width: auto;
  }

  .input-date-select .material-icons {
    top: 30%;
    left: 82%;
  }

  [type='checkbox']+span:not(.lever) {
    padding-left: 22px;
  }

  .room-guest-select {
    left: 17% !important;
  }

  .room-guest-select::before {
    width: 70.9% !important;
    left: 117.5px !important;
  }

  .modal {
    width: 80% !important;
  }

  .imgSlider .about-slider>div>div>div>div {
    width: 787px !important;
  }

  /* Media Query for Single Date Picker */
  .hero-box-singledatepicker {
    width: 70% !important;
  }

  .tab-screen {
    width: 85px !important;
    top: 12px !important;
    left: 50% !important;
    -webkit-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }

  .main-nav li a {
    padding: 0 8px !important;
    margin: 0px 7px !important;
  }

  .main-nav li {
    margin-top: 4.5% !important;
  }

  .non-refundable-text {
    font-size: 70%;
    margin-left: 20px;
  }

  .header-nav-wrapper {
    top: -40px !important;
  }
}

/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {
  .room-box-left {
    float: left;
    width: 70%;
  }

  .room-box-right {
    float: right;
    width: 30%;
  }

  header {
    background-size: auto;
  }

  .hero-text-box {
    width: 80%;
    margin-top: 8%;
  }

  .hero-box {
    position: absolute;
    top: 20%;
  }

  .room-select li:nth-child(3) {
    left: 7%;
  }

  .room-summary .room-summary-btn {
    background-color: var(--theme_color);
    height: 24px;
    line-height: 24px;
    padding: 0 0.5rem;
    margin-left: -23%;
  }

  .not-available li {
    text-align: center;
  }

  .partial-payment .half-pay span {
    font-size: 120%;
  }

  .partial-payment .full-pay span {
    margin-left: -25px;
    font-size: 120%;
  }

  .guest-details .tab .btn {
    height: 24px;
    line-height: 24px;
    padding: 0 0.2rem;
  }

  .input-date-select .material-icons {
    top: 26%;
    left: 76%;
  }

  .room-price-summary {
    padding: 15px 6px;
  }

  .partial-pay {
    padding: 15px 6px;
  }

  .price-small {
    font-size: 78%;
    font-weight: 600;
  }

  .not-available-btn {
    padding: 0 0px;
  }

  .DateInput:after {
    left: 73% !important;
    top: -105% !important;
    font-size: 140% !important;
  }

  .DateInput input[type='text']:not(.browser-default) {
    font-size: 75% !important;
  }

  .DateRangePicker_picker__directionLeft {
    left: -77.796875px !important;
  }

  .bm-burger-button {
    display: block !important;
  }

  .room-guest-select {
    left: 20% !important;
  }

  .room-guest-select::before {
    width: 79.9% !important;
    left: 80.5px !important;
  }

  .showMessage p {
    margin-left: 4%;
  }

  .showMessage-modify p {
    margin-left: 4%;
  }

  .modify-message p {
    margin-left: 2%;
  }

  .modal {
    width: 80% !important;
  }

  .imgSlider .about-slider>div>div>div>div {
    width: 589px !important;
  }

  /* Media Query for Single Date Picker */
  .hero-box-singledatepicker {
    width: 80% !important;
  }
}

/* Media query for icon to properly render on tab screen */
@media only screen and (max-width: 780px) {
  .tab-screen {
    width: unset;
    top: 30px !important;
    left: unset;
    -webkit-transform: unset;
    transform: unset;
  }

  .main-nav li a {
    padding: unset !important;
    margin: unset !important;
  }

  .main-nav li {
    margin-top: unset;
  }
}

/* Small phones to small tablets: from 620px to 767px */
@media only screen and (max-width: 767px) {
  .room-box-left {
    float: left;
    width: 100%;
  }

  .room-box-right {
    float: left;
    width: 100%;
    position: unset;
    top: 0;
    z-index: 2;
  }

  .booking-summary-box {
    width: 100%;
  }

  header {
    height: 40vh;
  }

  .hero-text-box {
    width: 100%;
    margin-top: -2%;
    padding: 0 3%;
  }

  .hero-box {
    position: absolute;
    top: 20%;
  }

  .hero-text-box h1 {
    font-size: 36px;
  }

  .hero-text-box h2 {
    font-size: 22px;
  }

  .input-date-select .material-icons {
    top: 30%;
    left: 88%;
  }

  .input-date-select input[type='text'] {
    padding: 0 3%;
  }

  .DateInput {
    width: auto !important;
  }

  .room-select .col {
    text-align: center;
    margin-bottom: 1.5%;
  }

  .room-select li:nth-child(3) {
    left: 7%;
  }

  .room-select li {
    margin-left: 0;
  }

  .discount-label {
    font-size: 60%;
  }

  .booking-summary-box {
    margin-left: 0 !important;
  }

  .room-summary-box {
    font-size: 110%;
  }

  .room-price-summary {
    font-size: 85%;
    padding: 15px 12px;
  }

  .partial-pay {
    font-size: 110%;
    padding: 15px 12px;
  }

  [type='checkbox']+span:not(.lever) {
    font-size: 1.3rem;
  }

  .partial-pay li:last-child {
    float: right;
  }

  .full-room-pay {
    font-size: 100%;
    padding: 15px 12px;
  }

  .price-small {
    font-size: 100%;
    font-weight: 400;
  }

  .modal .right-padding {
    text-align: center;
  }

  .day-list .check-in:after {
    display: block;
    content: 'Check In';
    margin-top: 2%;
    font-weight: 300;
  }

  .day-list .check-out:after {
    display: block;
    content: 'Check Out';
    margin-top: 2%;
    font-weight: 300;
  }

  .day-list:after {
    top: 35%;
  }

  footer .top-margin {
    text-align: center;
  }

  .DateInput:after {
    left: 85% !important;
  }

  .not-available-btn {
    padding: 0 16px;
  }
}

/* Small phones to small tablets: from 580px to 620px */
@media only screen and (max-width: 620px) {
  .room-box-left {
    float: left;
    width: 100%;
  }

  .room-box-right {
    float: left;
    width: 100%;
  }

  header {
    height: 118vh;
  }

  .hero-text-box {
    width: 100%;
    margin-top: 4%;
    padding: 0 3%;
  }

  .hero-box {
    position: absolute;
    top: 80%;
  }

  .booking-summary-box {
    margin-left: 0 !important;
  }

  .room-preview-bottom {
    margin-top: 4%;
  }

  .DateInput:after {
    left: 80% !important;
    top: -108% !important;
  }

  .not-available-btn {
    padding: 0 16px;
  }

  .day-list .check-in:after {
    margin-top: 0 !important;
  }

  .day-list .check-out:after {
    margin-top: 0 !important;
  }

  .day-list:after {
    top: none !important;
  }

  .showMessage {
    height: 75px;
    top: 270px;
  }

  .showMessage p {
    margin-left: 1%;
  }

  .showMessage-modify {
    top: 230px;
  }

  .showMessage-modify p {
    margin-left: 1%;
  }

  .modify-message {
    top: 230px;
  }

  .modify-message p {
    margin-left: 1%;
    font-size: 68% !important;
  }
}

/* Small phones to small tablets: from 481px to 580px */
@media only screen and (max-width: 580px) {
  .room-box-left {
    float: left;
    width: 100%;
  }

  .room-box-right {
    float: left;
    width: 100%;
  }

  header {
    height: 118vh;
  }

  .hero-text-box {
    width: 100%;
    margin-top: 4%;
    padding: 0 3%;
  }

  .hero-text-box h1 {
    font-size: 36px;
  }

  .hero-text-box h2 {
    font-size: 22px;
  }

  .hero-box {
    position: absolute;
    top: 80%;
  }

  .room-preview-bottom {
    margin-top: 4%;
  }

  .room-select li:nth-child(3) {
    width: 20%;
  }

  .DateInput:after {
    left: 78% !important;
    top: -108% !important;
  }

  .DateInput input[type='text']:not(.browser-default) {
    padding-left: 4% !important;
    font-size: 90% !important;
    width: 100% !important;
  }

  .room-guest-select .not-available-btn {
    padding: 0 16px;
  }

  .room-guest-select {
    width: 330px !important;
    top: 90%;
    left: 19.4% !important;
    margin: 0 auto;
  }

  .day-list:after {
    top: 45% !important;
  }

  /* CSS React Responsive Carousel [24-3-2020] */
  .carousel .slide img {
    width: 200px !important;
  }

  .booking-summary-box .carousel .slide img {
    width: 300px !important;
  }
}

/* Small phones: from 331px to 480px */
@media only screen and (max-width: 480px) {
  .maxHeightUnset {
    max-height: 70% !important;
  }

  .room-box-left {
    float: left;
    width: 100%;
  }

  .room-box-right {
    float: left;
    width: 100%;
  }

  header {
    height: 116vh;
    background-size: cover !important;
  }

  .hero-text-box {
    width: 100%;
    margin-top: 5%;
    padding: 0 3%;
  }

  .hero-text-box h1 {
    font-size: 27px;
  }

  .hero-text-box h2 {
    font-size: 18px;
  }

  .hero-box {
    position: absolute;
    top: 70%;
    margin-top: 50%;
  }

  .room-select li {
    margin-left: 8px;
    text-align: center;
  }

  .room-guest-select {
    width: 240px !important;
    top: 90%;
    left: 13.4% !important;
    margin: 0 auto;
  }

  .room-guest-select select {
    margin: 5% 0;
  }

  .room-guest-select .deleteIcon {
    right: 45% !important;
    font-size: 130% !important;
    margin-top: -15px;
  }

  .small-device-margin {
    margin-left: -4% !important;
  }

  .not-available-btn {
    padding: 0 16px;
  }

  .day-list:after {
    top: 45% !important;
  }

  .about-us-wrapper h2 {
    margin-left: 6%;
  }

  .policies-wrapper h2 {
    margin-left: 6%;
  }

  .multiple-room-wrap li {
    width: 100%;
    margin-left: 0px !important;
    margin-bottom: 6% !important;
  }

  .btn-guest {
    width: 100%;
  }

  .btn-guest-confirm {
    width: 100%;
  }

  .infants {
    width: 100% !important;
    padding-left: 15px !important;
  }

  .imgSlider .about-slider>div>div>div>div {
    width: 317px !important;
  }

  .hide-small {
    display: none;
  }

  /* CSS Update: Room Slider height [27-07-2020] */
  .imgSlider .carousel {
    height: auto !important;
  }

  .box-shadow-wrapper .carousel .slide img,
  .about-us-wrapper .carousel .slide img {
    height: 220px;
  }

  .headerBackground {
    background-attachment: unset;
  }

  .banner-section-hotel-name {
    display: block;
  }
}

/* Small phones: from 330 to 414px */
@media only screen and (max-width: 414px) {
  .DateRangePicker_picker__directionLeft {
    left: -44.796875px !important;
  }

  .room-guest-select {
    top: 93% !important;
    left: 22.4% !important;
  }

  .room-guest-select::before {
    width: 100.9% !important;
    left: 0.5px !important;
  }

  .login-wrapper {
    width: 100% !important;
  }

  .infants {
    width: 100% !important;
    padding-left: 15px !important;
  }

  .imgSlider .about-slider>div>div>div>div {
    width: 316px !important;
  }

  /* Media Query for Single Date Picker */
  .single-date-picker-wrap .SingleDatePicker_picker__directionLeft {
    left: -31.796875px !important;
  }
}

/* Small phones: from 360 to 375px */
@media only screen and (max-width: 375px) {
  .DateRangePicker_picker__directionLeft {
    left: -58.796875px !important;
  }

  .room-guest-select {
    top: 94% !important;
    left: 19.4% !important;
    border-bottom: 1px solid #dddddd;
  }

  .room-guest-select::before {
    width: 99.9% !important;
    left: -0.5px !important;
  }

  .imgSlider .about-slider>div>div>div>div {
    width: 288px !important;
  }

  /* Media Query for Single Date Picker */
  .single-date-picker-wrap .SingleDatePicker_picker__directionLeft {
    left: -49.796875px !important;
  }
}

/* Small phones: from 330 to 360px */
@media only screen and (max-width: 360px) {
  .room-guest-select {
    left: 17.4% !important;
  }

  .imgSlider .about-slider>div>div>div>div {
    width: 276px !important;
  }
}

/* Small phones: from 0 to 330px */
@media only screen and (max-width: 330px) {
  .room-box-left {
    float: left;
    width: 100%;
  }

  .room-box-right {
    float: left;
    width: 100%;
  }

  .input-date-select .material-icons {
    top: 26%;
    left: 84%;
  }

  .partial-pay {
    font-size: 110%;
    padding: 15px 6px;
  }

  .room-guest-select {
    width: 230px !important;
    top: 90%;
    left: 13.6% !important;
    margin: 0 auto;
  }

  .room-guest-select select {
    margin: 5% 0;
  }

  .not-available-btn {
    padding: 0 16px;
  }

  .day-list .check-out:after {
    margin-top: none;
  }

  .day-list:after {
    top: 45% !important;
  }

  .DateRangePicker_picker__directionLeft {
    left: -77.796875px !important;
  }

  .room-guest-select {
    left: 14.6% !important;
  }

  .imgSlider .about-slider>div>div>div>div {
    width: 264px !important;
  }

  /* Media Query for Single Date Picker */
  .single-date-picker-wrap .SingleDatePicker_picker__directionLeft {
    left: -69.796875px !important;
  }

  /* CSS React Responsive Carousel [24-3-2020] */
  .carousel .slide img {
    height: 160px !important;
  }

  .booking-summary-box .carousel .slide img {
    height: 230px !important;
  }
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 1024px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .table-content-wrapper h2 {
    text-align: center !important;
    margin: 4% 0 !important;
  }

  .table-content-wrapper table {
    width: 100%;
  }

  /* Force table to not be like tables anymore */
  .table-content-wrapper table {
    display: block;
  }

  .table-content-wrapper thead {
    display: block;
  }

  .table-content-wrapper tbody {
    display: block;
  }

  .table-content-wrapper th {
    display: block;
  }

  .table-content-wrapper td {
    display: block;
  }

  .table-content-wrapper tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .table-content-wrapper thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table-content-wrapper tr {
    border: 1px solid #eee;
  }

  .table-content-wrapper td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
  }

  .table-content-wrapper td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);

    color: #000;
    font-weight: 600;
  }
}